import React from "react";
import moment from "moment";

import { UikFormInputGroup, UikInput, UikButton, UikDivider } from "@uik";

import NewModal from "../../../Common/Modal";
import styles from "./PreviewEmailModal.module.scss";

export default (props) => {
  const { selectedEmail, selectedCampaign, closeEmailPreview } = props;

  const renderForm = () => {
    return (
      <UikFormInputGroup className={styles.formContainer}>
        {["From", "To", "Subject", "Body"].map((propertyLabel) => {
          return renderPropertyInput(propertyLabel);
        })}
        <div>
          {selectedCampaign.status !== "manual_send" &&
            renderPropertyInput("Send Date")}
        </div>
      </UikFormInputGroup>
    );
  };

  const handleClose = () => {
    closeEmailPreview();
  };

  const renderPropertyInput = (propertyLabel) => {
    let propertyId = propertyLabel.toLowerCase().replace(/ /, "_");

    if (propertyLabel === "Body") {
      return (
        <div className={styles.customInputContainer}>
          <div>
            <div className={styles.label}>
              <span className="uik-content-title__wrapper">Body</span>
            </div>
            <div>
              <p
                className={styles.formattedBody}
                dangerouslySetInnerHTML={{ __html: selectedEmail.body }}
              ></p>
            </div>
          </div>
        </div>
      );
    } else if (propertyId === "send_date") {
      return (
        <div>
          <UikInput
            label={`${propertyLabel}`}
            placeholder={`${propertyLabel}`}
            disabled={true}
            value={
              selectedEmail["sent_at"]
                ? moment(selectedEmail["sent_at"]).format(
                    "MMMM Do YYYY, h:mm a"
                  )
                : moment(selectedCampaign["send_date"]).format(
                    "MMMM Do YYYY, h:mm a"
                  )
            }
          />
        </div>
      );
    } else if (propertyId === "to") {
      return (
        <div>
          <UikInput
            label={`${propertyLabel}`}
            placeholder={`${propertyLabel}`}
            disabled={true}
            value={`${selectedEmail["recipient_name"]} (${selectedEmail[propertyId]})`}
          />
        </div>
      );
    } else {
      return (
        <div>
          <UikInput
            label={`${propertyLabel}`}
            placeholder={`${propertyLabel}`}
            disabled={true}
            value={selectedEmail[propertyId]}
          />
        </div>
      );
    }
  };

  return (
    <NewModal visible={true} type="regular">
      <div className="heading">Email Preview</div>
      <UikDivider />
      <div className="body">
        <div>{renderForm()}</div>
      </div>
      <div className="clear" />
      <div className="dividerContainer">
        <UikDivider />
      </div>
      <div className="buttons">
        <div className={styles.buttonContainer}>
          <div className={styles.rightContainer}>
            <UikButton onClick={() => handleClose()}>Cancel</UikButton>
          </div>
        </div>
      </div>
    </NewModal>
  );
};
